<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
    <v-row no-gutters class="mb-3">
      <v-col cols="12" sm="6" offset="3">
        <div class="text-h3 font-weight-bold text-center" v-if="!isNewAccount">
          Conta: #{{ account.id }} {{ account.account }}
          <br />
          Grupo: {{ group.account }}
        </div>
        <div class="text-h3 font-weight-bold text-center" v-if="isNewAccount">
          Cadastro de loja
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="amber" v-if="account.active === 0">
        <i class="fas fa-exclamation-triangle fa-3x white--text mt-2 ml-2"></i>
        <div class="text-h3 font-weight-bold text-center d-inline white--text ml-2 mt-n1" style="position: relative; bottom: 6px;">
          Conta bloqueada
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!isNewAccount">
      <v-col cols="12" md="12">
        <v-bottom-navigation horizontal color="primary" :value="currentStepIndex">
          <v-btn :value="step.routerName" v-for="step in steps" :key="step.step" @click="navigate(step)" :class="{ 'v-btn--active' : step.routeName === currentStepRoute }">
            <span>{{ step.title }}</span>
            <v-icon :class="step.icon"></v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { assistentSteps } from '@/code/util/constants';
import { EventBus } from '@/code/util/eventBus';
import { AccountService } from '@/code/services/user/accountService';
export default {

  data() {
    return {
      currentStepIndex: 0,
      currentStepRoute: '',
      steps: assistentSteps,
      idAccount: 0,
      account: {},
      group: {},
      isNewAccount: true
    }
  },

  mounted() {
    EventBus.$on('nextAssistentRoute', this.nextTab);
    EventBus.$on('previousAssistentRoute', this.previousTab);

    this.prepareWizard();
  },

  methods: {
    prepareWizard: function() {
      this.currentStepRoute = this.$route.name;
      const nextStep = assistentSteps.filter((s) => s.routeName === this.currentStepRoute);
      if (nextStep && nextStep.length > 0) {
        this.currentStepIndex = nextStep[0].step - 1;
      }
      this.idAccount = this.$route.params.id;

      if (this.$route.name === 'assistentNewAccount') {
        this.isNewAccount = true;
      } else {
        this.isNewAccount = false;
        if (!this.idAccount || this.idAccount <= 0) {
          Swal.fire({
            title: 'Parâmetro inválido',
            text: 'A loja informada é invalida!',
            icon: 'error'
          })
          .then(
            (result) => {
              that.$router.replace({ name: 'assistentAction' });
            }
          )
        } else {
          this.loadAccount();
        }
      }
    },
    loadAccount: async function() {
      const service = new AccountService();
      this.account = await service.get(this.idAccount);
      this.loadGroup();
    },
    loadGroup: async function() {
      const service = new AccountService();
      this.group = await service.get(this.account.parent_id);
    },
    navigate: function(step) {
      if (this.currentStepRoute !== step.routeName) {
        this.currentStepRoute = step.routeName;
        this.$router.push({ name: step.routeName, params: { id: this.idAccount } });
      }
    },
    nextTab: function(idAccount) {
      if ((!idAccount || idAccount <= 0) && (!this.idAccount || this.idAccount <= 0)) {
        Swal.fire({
          title: 'Parâmetro inválido',
          text: 'A loja informada é invalida!',
          icon: 'error'
        })
        .then(
          (result) => {
            that.$router.replace({ name: 'assistentAction' });
          }
        )
      } else {
        const that = this;
        if (that.currentStepRoute === 'assistentNewAccount') {
          that.$router.push({ name: assistentSteps[1].routeName, params: { id:idAccount }});
        }
        const currentStep = assistentSteps.filter((s) => s.routeName === that.currentStepRoute);
        if (currentStep && currentStep.length > 0) {
          const nextStep = assistentSteps.filter((s) => s.step === currentStep[0].step + 1);
          if (nextStep && nextStep.length > 0) {
            this.currentStepIndex = nextStep[0].step - 1;
            this.currentStepRoute = nextStep[0].routeName;
            that.$router.push({ name: nextStep[0].routeName, params: { id: that.idAccount }});
          } else {
            that.$router.push({ name: 'assistentAction' });
          }
        }
      }
    },
    previousTab: function() {
      const that = this;
      const currentStep = assistentSteps.filter((s) => s.routeName === that.currentStepRoute);
      if (currentStep && currentStep.length > 0) {
        const previousStep = assistentSteps.filter((s) => s.step === currentStep[0].step - 1);
        if (previousStep && previousStep.length > 0) {
          this.currentStepIndex = previousStep[0].step - 1;
          that.currentStepRoute = previousStep[0].routeName;
          that.$router.push({ name: previousStep[0].routeName, params: { id: that.idAccount }});
        } else {
          that.$router.push({ name: 'assistentAction' });
        }
      } else {
        that.$router.push({ name: 'assistentAction' });
      }
    }
  },

  watch: {
    $route (to, from){
      this.prepareWizard();
    }
  }

}
</script>

<style>
  button.v-btn.v-btn--active.theme--light {
    color: #fff !important;
    background-color: #3b5998 !important;
  }
</style>
